<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione uma Disciplina </v-card-title>

        <v-select
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplina"
          return-object
          label="Selecione uma disciplina"
          solo
          @change="verRota()"
        ></v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinas: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    typeModel: {
      type: String,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
    typeModel(val) {
      this.type = val;
    },
  },
  data() {
    return {
      disciplinasArray: this.disciplinas,
      disciplina: null,
      disciplinasDaGestao: [],
      type: null,
    };
  },
  methods: {
    verRota() {
      if (this.type === "notas") {
        this.$router.push({
          name: "notas.circuitoNotas",
          params: { gestaoDeAulasId: this.gestaoDeAulasId, disciplinaId: this.disciplina.id },
        });
      }

      if (this.type === "planos") {
        this.$router.push({
          name: "gestoesDeAulas.planoBimestralCreate",
          params: { gestaoDeAulaId: this.gestaoDeAulasId, disciplinaId: this.disciplina.id },
        });
      }
    },
  },
};
</script>

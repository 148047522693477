var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-template',[_c('v-row',[_c('v-col',[_c('h1',[_c('v-btn',{attrs:{"color":"primary","x-small":"","text":"","disabled":false},on:{"click":() => _vm.$router.push({ name: 'turmas' })}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-angle-left ")])],1),_vm._v(" Turma ")],1)])],1),_c('v-row',[(_vm.turma)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-5",attrs:{"color":"green lighten-1","outlined":""}},[_c('v-card-title',[_vm._v(" Turma: "+_vm._s(_vm.turma.descricao))]),_c('v-card-subtitle',{staticClass:"font-weight-medium"},[_c('div',[_vm._v(" Professor(a): "),_c('strong',[_vm._v(_vm._s(_vm.professor.nome || "- - -"))])]),_c('div',[_vm._v(" Total de alunos: "),_c('strong',[_vm._v(_vm._s(_vm.matriculas.length || "- - -"))])]),_c('div',[_vm._v(" Tipo de atendimento: "),_c('strong',[_vm._v(_vm._s(_vm.turma.tipo_de_atendimento || "- - -"))]),_vm._v(" | Tipo de ensino: "),_c('strong',[_vm._v(_vm._s(_vm.turma.curso?.descricao || "- - -"))])]),_c('div',[_vm._v(" Tipo de relaltório: "),_c('strong',[_vm._v(_vm._s(_vm.turma.sistema_bncc_id > 0 ? "BNCC" : "Personalizado"))])])])],1)],1):_vm._e()],1),_c('v-card',{staticClass:"mt-3"},[[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab_atual),callback:function ($$v) {_vm.tab_atual=$$v},expression:"tab_atual"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:_vm.tabs.indexOf(tab)},[_vm._v(_vm._s(tab.descricao))])}),1),_c('div',[_c('v-btn',{staticClass:"ml-4 mt-4 mr-4 white--text",attrs:{"color":"primary"},on:{"click":_vm.gerarRelatorio}},[_vm._v(" Lista da turma "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file ")])],1),_c('v-btn',{staticClass:"ml-4 mt-4 mr-4 white--text",attrs:{"color":"primary"},on:{"click":() =>
              _vm.$router.push({
                name: 'notas.circuitoNotasInfantil',
                params: {
                  turmaId: this.$route.params.turmaId,
                },
              })}},[_vm._v(" Lançar Avaliação de Des. Infantil "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file ")])],1),_c('v-btn',{staticClass:"ml-4 mt-4 mr-4 white--text",attrs:{"color":"primary"},on:{"click":_vm.gerarBoletimGeral}},[_vm._v(" Gerar Avaliação de Des. Infantil "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" class ")])],1)],1),_c('v-card-text',[(_vm.matriculas)?_c('v-simple-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_atual == 0),expression:"tab_atual == 0"}],staticClass:"elevation-1",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("N º")]),_c('th',{staticClass:"text-left",attrs:{"width":"300px"}},[_vm._v("Aluno")]),_c('th',{staticClass:"text-center",attrs:{"width":"15%"}},[_vm._v("Ações")])])]),_c('tbody',_vm._l((_vm.matriculas),function(matricula){return _c('tr',{key:matricula.id,class:!matricula.aluno ? 'accent' : ''},[_c('td',{attrs:{"width":"20"}},[_vm._v(_vm._s(_vm.matriculas.indexOf(matricula) + 1))]),_c('td',{staticClass:"font-weight-medium text-left"},[_c('div',[_vm._v(" "+_vm._s(matricula.aluno ? matricula.aluno.nomecompleto : `[ALUNO POSSIVELMENTE DELETADO] MATRICULA: ${matricula.id}`)+" ")]),_c('div',[_c('small',[_vm._v(" "+_vm._s(matricula.aluno ? matricula.aluno.codigo_inep : "- - -")+" / "+_vm._s(matricula.aluno ? matricula.aluno.codigo : "- - -")+" ")])])]),_c('td',{staticClass:"text-center"},[(_vm.turma.sistema_bncc_id ? true : false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"orange lighten-3","to":{
                          name: 'relatorios.lancarRelatorioBNCC',
                          params: { matricula_id: matricula.id },
                        }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Lançar Resumo de Desenvolvimento Individual")])]):_vm._e(),(_vm.turma.sistema_bncc_id ? true : false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","color":"beige lighten-5"},on:{"click":() => _vm.RelatorioResumoIndividual(matricula.id)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"lighten-1","small":""}},[_vm._v("mdi-file-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Gerar Resumo de Desenvolvimento Individual")])]):_vm._e(),(_vm.turma.sistema_bncc_id ? true : false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","color":"blue lighten-4","to":{
                          name: 'relatorios.lancarRelatorioDescritivo',
                          params: { matricula_id: matricula.id },
                        }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-invoice")])],1)]}}],null,true)},[_c('span',[_vm._v("Lançar relatório descritivo da(e) "+_vm._s(_vm.turma.curso.descricao))])]):_vm._e(),(_vm.turma.sistema_bncc_id ? true : false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","color":"red lighten-4"},on:{"click":() => _vm.RelatorioDescritivo(matricula.id)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-1","small":""}},[_vm._v(" fa fa-file-pdf")])],1)]}}],null,true)},[_c('span',[_vm._v(" Gerar relatório descritivo da(e) "+_vm._s(_vm.turma.curso.descricao))])]):_vm._e(),(matricula.aluno.especial == 1 || _vm.turma.curso_id === 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"blue lighten-3","to":{
                          name: 'educacaoInclusiva.create',
                          params: { matricula_id: matricula.id },
                        }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-universal-access")])],1)]}}],null,true)},[_c('span',[_vm._v("Lançar relatório AEE (ATENDIMENTO EDUCACIONAL ESPECIALIZADO)")])]):_vm._e(),(matricula.aluno.especial === 1 || _vm.turma.curso_id === 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","color":"red lighten-5"},on:{"click":() => _vm.relatorioAee(matricula.id)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-1","small":""}},[_vm._v(" fa fa-file-pdf")])],1)]}}],null,true)},[_c('span',[_vm._v("Relatório AEE (ATENDIMENTO EDUCACIONAL ESPECIALIZADO)")])]):_vm._e()],1)])}),0)]},proxy:true}],null,false,3102751499)}):_vm._e()],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas.planos' })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Plano Bimestral
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn depressed color="primary" @click="() => [visualizarPdf(plano.id), (dialog = true)]">
          Visualizar Pdf
          <v-icon small right> mdi-note </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="10" md="12" lg="12">
        <v-card>
          <v-card-text>
            <label for="">Professor</label>
            <v-input>
              {{
                plano.gestao_de_aula?.professor ? plano.gestao_de_aula.professor?.nome : "- - - "
              }}
            </v-input>

            <label for="">Turma</label>
            <v-input>
              {{ plano.gestao_de_aula?.turma ? plano.gestao_de_aula.turma?.descricao : "- - - " }}
            </v-input>

            <label for="">Disciplina</label>
            <v-input>
              {{
                plano.gestao_de_aula?.disciplina
                  ? plano.gestao_de_aula.disciplina?.descricao
                  : "- - - "
              }}
            </v-input>

            <label for="">Data lançamento</label>
            <v-input>
              <v-chip>
                {{
                  plano ? plano.created_at?.substr(0, 10).split("-").reverse().join("/") : "- - - "
                }}
              </v-chip>
            </v-input>

            <label for="">Ultima atualização</label>
            <v-input>
              <v-chip>
                {{
                  plano ? plano.updated_at?.substr(0, 10).split("-").reverse().join("/") : "- - - "
                }}
              </v-chip>
            </v-input>
            <v-combobox v-model="seriesSelecionadas" chips label="Series" multiple readonly />
          </v-card-text>
        </v-card>

        <v-card class="mt-5">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <label for="">Status</label>
                <e-situacao-plano :plano="plano" v-on:update="listarPlano" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>

                <label for="">Bimestre</label>
                <v-input>
                  {{ plano.bimestre ? plano.bimestre.descricao : "Sem Descrição" }}
                </v-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label for="">UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.tematica ? plano.tematica : "- - -" }}
                </v-input>
              </v-col>
            </v-row>

            <!-- Conteúdo | Assunto | Objetos de conhecimento  -->
            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label>OBJETOS DO CONHECIMENTO</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.objeto_conhecimento ? plano.objeto_conhecimento : "- - -" }}
                </v-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label>HABILIDADES</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.habilidades ? plano.habilidades : "- - -" }}
                </v-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label>METODOLOGIA</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.metodologia ? plano.metodologia : "- - -" }}
                </v-input>
              </v-col>
            </v-row>

            <!-- RECURSOS DIDÁTICOS -->
            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label>RECURSOS DIDÁTICOS</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.recursos_didaticos ? plano.recursos_didaticos : "- - -" }}
                </v-input>
              </v-col>
            </v-row>

            <!-- AVALIAÇÃO DA APRENDIZAGEM -->
            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label>AVALIAÇÃO DA APRENDIZAGEM</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.avaliacao_aprendizagem ? plano.avaliacao_aprendizagem : "- - -" }}
                </v-input>
              </v-col>
            </v-row>

            <!-- REFERÊNCIAS -->
            <v-row>
              <v-col>
                <v-divider></v-divider>

                <label>REFERÊNCIAS</label>
                <v-input filled rounded dense color="primary">
                  {{ plano.referencias ? plano.referencias : "- - -" }}
                </v-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ModalCarteira :dialog="dialog" :pdf="pdf" />
  </main-template>
</template>

<script>
import ModalCarteira from "@/components/Matriculas/EModalCarteira.vue";

export default {
  components: {
    ModalCarteira,
  },
  data() {
    return {
      dialog: false,
      pdf: null,
      showSnackbar: false,
      plano: {},
      plano_id: null,
      seriesSelecionadas: [],
      habilidadeSelecionadas: [],
      recursosSelecionados: [],
      avaliacoesSelecionadas: [],
      duvidasSelecionadas: [],
    };
  },
  async mounted() {
    this.getId();
    this.listarPlano(this.pageNumber);
  },
  methods: {
    async getId() {
      const { id } = this.$route.params;
      this.plano_id = id;
    },
    async listarPlano() {
      this.$loaderService.open("Carregando Plano");
      try {
        const { data } = await this.$services.planosService.show(this.plano_id);
        this.plano = { ...data.planoMensal };
        this.seriesSelecionadas = this.plano.gestao_de_aula.turma.series.map((s) => s.descricao);
      } catch (error) {
        this.$loaderService.close();
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async visualizarPdf(planoId) {
      this.pdf = await this.$services.planosService.pdf(planoId);
    },
  },
};
</script>

<style></style>

import Axios from "@/plugins/Axios";

class HistoricoService {
  async syncAll(aluno_id) {
    const response = await Axios().get(`historico/${aluno_id}`);
    return response.data;
  }

  async baixarHistorico(aluno_id) {
    const response = await Axios()
      .get(`generateHistoricoPDF/${aluno_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ficha_individual_aluno_${aluno_id}.pdf`;
        link.click();
      });
    return response;
  }

  async criarHistorico(historico) {
    return Axios().post("historico", historico);
  }

  async atualizarHistorico(historico) {
    return Axios().put("historico", historico);
  }

  async pegarHistorico(historico_id) {
    const response = await Axios().get(`historico-item/${historico_id}`);
    return response.data;
  }

  async pegarAluno(aluno_id) {
    const response = await Axios().get(`alunos/${aluno_id}`);
    return response.data;
  }

  async deletarItem(historico_id) {
    const response = await Axios().delete(`historico/${historico_id}`);
    return response.data;
  }
}

export default new HistoricoService();
